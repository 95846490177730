import React, { useEffect, useState, useRef } from 'react';
import '../css/swagger-ui.css';
import { json } from '@codemirror/lang-json';
import WSBlock from './WSBlock.jsx';
import { Button, Icon, Segment } from 'semantic-ui-react';
import { getJsonReference, resolveRefs } from '../lib/widget-utils'
import Swagger from 'swagger-client';

const OpenAPIBlock = ({ url, addTab, help }) => {

  const [buffer, setBuffer] = useState();
  const [alltabs, setAllTabs] = useState([]);
  let tags = [];

  const loadData = () => {
    Swagger(url).then(async res => {
      // Check if the response is OK (status code 200-299)
      //let apiDoc = await res.json();
      const mfgWs = res.spec; //await deref(apiDoc); //res.spec;
      let newTabs = [];
      let keys = Object.keys(mfgWs.paths);
      let i = 0;
      for (let path of keys) {
        let ws = mfgWs.paths[path];
        let xse = `{${mfgWs.info?.['x-ds-service']?.[0]}}`;
        let server = mfgWs.servers?.[0]?.url || xse;
        let basePath = mfgWs.basePath || '';
        let types = Object.keys(ws);
        for (let type of types) {
          let data = {...ws[type], ...{server_url: server}};
          const newTab = { id: crypto.randomUUID(), title: `${data.operationId || data.summary}`, name: path, path: basePath + path, type: `${type}`, data: data, components: mfgWs.components };
          newTabs.push(newTab);
        }
      }
      setBuffer(mfgWs);
      setAllTabs(newTabs);
    })
  }
  const buildHelpUrl = (tab) => {
    let tag = tab.data?.tags?.[0];
    let extra = tab?.data?.__originalOperationId || tab?.data?.operationId || `${tab.type}${tab.name?.replaceAll('/', '_')?.replace(/{|}/g, "_")}`;
    return `${help}#/${tag}/${extra}`;
  }

  useEffect(() => {
    loadData();
  }, [url]);

  return (
    <>
      <Segment secondary>
        <h2 className="title">
          {buffer?.info?.title}
          {help && <a className='button circular primary icon ui' style={{position: 'absolute', right: '10px'}} href={help} target='_blanc'>
            <Icon name='help' />
          </a>}
        </h2>
        <Button size='mini' color='olive'>{buffer?.info?.version}</Button>
        <Button size='mini' color='grey'>OAS {buffer?.openapi || buffer?.swagger}</Button>

        <div className="info">
          <hgroup className="main">
          </hgroup>
          <div className="description">
            <div className="markdown">
              <br />
              <pre style={{ fontFamily: 'unset', fontSize: '1rem' }} dangerouslySetInnerHTML={{ __html: buffer?.info?.description}} />
              <li>{buffer?.info?.['x-ds-copyright']}</li>
            </div>
          </div>
        </div>
      </Segment>
      {alltabs?.filter(el => el.type != 'parameters').map((tab, i) => {
        let tag = tab.data?.tags?.[0];
        let tagDisplayed = tags.includes(tag);
        if (!tagDisplayed) tags.push(tag);
        return <div key={'tab-'+i} className='p-10'>
          {!tagDisplayed && <h3 className="opblock-tag no-desc">
            <a className="nostyle" href="#/Providers">
              <span>{tag}</span>
            </a>
          </h3>}
          <WSBlock key={`ws-${i}`} tab={tab} collapsed={true} onClick={() => {
            tab.id = crypto.randomUUID();
            addTab({...tab, help: buildHelpUrl(tab)});
          }} />
        </div>
      })}
    </>
  )

}

export default OpenAPIBlock;
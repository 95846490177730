import React, { useEffect, useState, useRef } from 'react';
import '../css/swagger-ui.css';
import CodeMirror from '@uiw/react-codemirror';
import { EditorView } from "@codemirror/view"
import { json } from '@codemirror/lang-json';
import { monokai, monokaiInit } from '@uiw/codemirror-theme-monokai';
import { useDSContext } from './DSContext.jsx';
import ParamBlock from './ParamBlock.jsx';
import {
    showPopup, decodeHtml,
    generateExampleFromSchema,
} from '../lib/widget-utils'
import { Button, Icon, Input, Dropdown, Divider, Grid, GridColumn, GridRow, Tab, TabPane, Segment, TextArea } from 'semantic-ui-react';

const WSBlock = ({ tab, collapsed, onClick }) => {
    const [payload, setPayload] = useState(tab.payload || { body: undefined, headers: {}, path: {}, query: {} });
    const [parameters, setParameters] = useState(tab.data?.parameters || []);
    const [response, setResponse] = useState();
    const [examples, setExamples] = useState([])
    const [loading, setLoading] = useState(false);
    const [isHelp, showHelp] = useState();
    const [isCustom, setCustom] = useState(tab.isCustom);
    const [url, setURL] = useState();
    const [path, setPath] = useState(tab.path || '');
    const dsLib = useDSContext();
    const fileInputRef = useRef(null);
    const paramInputRef = useRef(null);
    const headerInputRef = useRef(null);
    const dropsRef = useRef([]);
    const [showBodyShema, setShowBodyShema] = useState(false);
    const [requestBody, setRequestBody] = useState({});
    const [contextMenu, setContextMenu] = useState(null);

    const generateURL = () => {
        let server_url = dsLib?.servers?.[tab.data?.server_url || '{undefined}'];
        let urlRoot = dsLib?.urlRoot || '';
        let baseUrl = !path?.startsWith('http') ? (!server_url?.startsWith('http') ? urlRoot + server_url : server_url) : '';
        setURL(baseUrl + path?.replace(/{([^}]+)}/g, (match, key) => {
            // For each placeholder, replace it with the corresponding value from the payload
            return payload.path.hasOwnProperty(key) ? payload.path[key?.toLowerCase()] : match; // Return the original match if key not found in payload
        }));
    }

    const runWS = () => {
        setLoading(true);
        setResponse();
        let cookie = dsLib.widget.getValue('cookie');
        if(cookie){
            let _headers = {...payload.headers, ...{'Cookie': cookie}};
            setPayload({...payload, ...{headers: _headers}})
        }
        dsLib.callDataAsync(tab.type, url, payload).then(res => {
            setResponse(res);
        }).catch(err => {
            const ishtml = err.ishtml; delete err.ishtml;
            if (ishtml && err.data) showPopup(err.data.message || err.data);
            setResponse(ishtml ?  (err.data.trim ? err.data.trim() : err.data) : (err?.stack ? `${err}` : err));
        }).finally(() => setLoading(false));
    }

    const onBodyChange = React.useCallback((val, viewUpdate) => {
            setPayload({ ...payload, ...{ body: JSON.isValid(val) ? JSON.parse(val) : val }});
    }, []);

    const onPayloadChange = React.useCallback((val, viewUpdate) => {
        setPayload(JSON.parse(val));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let param = parameters?.find(e => name === e.name);
        if ('header' === param?.in)
            payload.headers[name] = value;
        else if (path?.indexOf(`{${name}}`) !== -1)
            payload.path[name] = value;
        else
            payload.query[name] = value;
        setPayload({ ...payload });
        generateURL();
    }

    const handleOptionClick = async (event, value) => {
        navigator.clipboard.writeText(value).then(res => {
            let newValue = contextMenu.target.innerText?.replaceAll(contextMenu.text, value);
            if(contextMenu.target) contextMenu.target.innerText = newValue;
            setContextMenu(null);        
        });
    };

     const handleRightClick = event => {
        if(!dsLib.droppedObjects?.length) return;
        const selectedText = window.getSelection().toString();
        if (selectedText && typeof event?.target?.getClientRects === 'function') {
             event.preventDefault();
             let { x, y } = event.target.getClientRects()?.[0];
             setContextMenu({
                 target: event.target,
                 text: selectedText,
                 mouseX:  event.clientX,
                 mouseY:  event.clientY,
             });
         } else {
             setContextMenu(null);
         }
      };


    const addParam = (e) => {
        const paramName = paramInputRef?.current?.inputRef?.current?.value;
        if (paramName && !parameters?.some(param => param.name === paramName)) {
            setParameters([...parameters, { name: paramName, in: 'query', added: true }]);
        }
    };

    const addHeader = (e) => {
        const paramName = headerInputRef?.current?.inputRef?.current?.value;
        if (paramName && !parameters?.some(param => param.name === paramName)) {
            setParameters([...parameters, { name: paramName, in: 'header', added: true }]);
        }
    }

    const addFile = (e) => { }

    useEffect(() => {
        if (!collapsed) {
            generateURL();
        }
    }, [dsLib.widget, payload, path]);

    useEffect(() => {
       tab.payload = payload;
    }, [payload]);

    useEffect(() => {
        tab.path = path;
     }, [path]);

    useEffect(() => {
        if(!tab.data) tab.data = {};
        tab.data.parameters = parameters;
     }, [parameters]);

    useEffect(() => {
        try {
            if (!collapsed) {
                const content = tab.data?.requestBody?.content || {};
                const contentKey = Object.keys(content)[0];
                if (contentKey) payload.headers['Content-Type'] = contentKey;
                let requestBody = content?.[contentKey]?.schema;
                let contentExamples = content?.[contentKey]?.examples || [];
                if (requestBody) contentExamples.Default = {
                    value: requestBody.example ||
                        (requestBody.type === 'array' ? [generateExampleFromSchema(requestBody)]
                            : generateExampleFromSchema(requestBody))
                };

                if (contentExamples) {
                    setExamples(Object.keys(contentExamples).map(key => (
                        {
                            name: key,
                            value: contentExamples[key] //generateExampleFromSchema(contentExamples[key]) 
                        }
                    )));
                }
                //remove $$ref
                delete requestBody?.$$ref;
                setRequestBody(requestBody);
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    const calcBody =showBodyShema ? JSON.format(JSON.stringify(requestBody)) : (typeof payload.body === 'object' ? JSON.format(JSON.stringify(payload.body)) : payload.body);

    const panes = [
        {
            menuItem: 'Parameters',
            render: () => <TabPane className="parameters-container" attached={false}>
                <div className="table-container">
                    <table className="parameters">
                        <thead>
                            <tr>
                                <th className="col_header parameters-col_name">Name</th>
                                <th className="col_header parameters-col_description">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {parameters
                                ?.filter(param => 'header' !== param.in)
                                ?.map((param, id) => <ParamBlock
                                    key={`param-${id}`}
                                    ref={(el) => (dropsRef.current[id] = el)}
                                    param={param}
                                    payload={{ ...payload.query, ...payload.path }}
                                    handleInputChange={handleInputChange}
                                    handleRightClick={handleRightClick}
                                />)}
                        </tbody>
                    </table>
                    <div>
                        <Input type='text' ref={paramInputRef} action={{ icon: 'plus', secondary: true, onClick: addParam, className: 'my-5' }} placeholder='+ Add Param...' />
                    </div>
                </div>
            </TabPane>,
        },
        {
            menuItem: 'Body',
            render: () => <TabPane display='none' className="parameters-container" attached={false}>
                <div className="table-container">
                    <table className="parameters">
                        <thead>
                            <tr>
                                <th className="col_header parameters-col_name">Name</th>
                                <th className="col_header parameters-col_description">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="parameters-col_name">
                                    <div className="parameter__name required">Body<span>&nbsp;*</span></div>
                                </td>
                                <td>
                                    <div className='flex-l'>
                                        <Button.Group size='tiny'>
                                            <Button className={!showBodyShema ? 'ui secondary label' : ''} onClick={() => setShowBodyShema(false)} >Edit</Button>|
                                            <Button className={showBodyShema ? 'ui secondary label' : ''} onClick={() => setShowBodyShema(true)} >Shema</Button>
                                        </Button.Group>
                                        {examples?.length > 0 &&
                                            <div className='absolute-l right-2'>
                                                <span className="examples-select__section-label">Examples: </span>
                                                <select className="examples-select-element" onChange={(e) => {
                                                    let { value } = examples.find(m => m.name === e.target.value)?.value || { value: {} };
                                                    setPayload({ ...payload, ...{ body: value } })
                                                }}>
                                                    <option key='00' value={null}>--</option>
                                                    {examples.map((example, indx) => <option key={indx} value={example?.name}>{example?.name}</option>)}
                                                </select>
                                            </div>}
                                    </div>
                                    <CodeMirror
                                        minHeight='200px'
                                        onContextMenu={handleRightClick}
                                        basicSetup={{ "lineNumbers": false }}
                                        editable={!showBodyShema}
                                        theme={monokai}
                                        onChange={onBodyChange}
                                        value={calcBody}
                                        extensions={[json(), EditorView.lineWrapping]}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <div className="ui acti">
                            <input
                                type="file"
                                id="fileInput"
                                ref={fileInputRef}
                                onChange={addFile}
                                style={{ display: "none" }}
                            />
                            <label htmlFor="fileInput" className="ui icon secondary button">
                                <Icon name="upload" />
                                {fileInputRef?.current?.files || ' Choose File ...'}
                            </label>
                        </div>
                    </div>
                </div>
            </TabPane>,
        },
        {
            menuItem: 'Headers',
            render: () => <TabPane className="parameters-container" attached={false}>
                <div className="table-container">
                    <table className="parameters mw-200">
                        <thead>
                            <tr>
                                <th className="col_header parameters-col_name">Name</th>
                                <th className="col_header parameters-col_description">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                parameters
                                    ?.filter(param => 'header' === param.in)
                                    ?.map((param, id) => <ParamBlock
                                        key={`param-${id}`}
                                        ref={(el) => (dropsRef.current[id] = el)}
                                        param={param}
                                        payload={payload.headers}
                                        handleRightClick={handleRightClick}
                                        handleInputChange={handleInputChange}
                                    />)
                            }
                        </tbody>
                    </table>
                    <div className='my-10'>
                        <Input type='text' ref={headerInputRef} action={{ icon: 'plus', secondary: true, onClick: addHeader, className: 'my-5' }} placeholder='+ Add Header...' />
                    </div>
                </div>
            </TabPane>,
        },
        {
            menuItem: 'URL',
            render: () => <TabPane className="parameters-container" attached={false}>
                <div className="table-container">
                    <table className="parameters">
                        <thead>
                            <tr>
                                <th className="col_header parameters-col_name">Name</th>
                                <th className="col_header parameters-col_description">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="parameters-col_name">
                                    <div className="parameter__name required">URL<span>&nbsp;*</span></div>
                                    <div className="parameter__type">string</div><div className="parameter__in">(path)</div>
                                </td>
                                <td>
                                    <div className="renderedMarkdown"><p>Webservie call URL</p></div>
                                    <Input type='text'
                                        onChange={(e, { value }) => setURL(value)}
                                        className='w-100' value={`${url}`}
                                        action={<Button style={{ margin: '5px 0' }} size='mini' icon="play" onClick={generateURL} />}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </TabPane>,
        },
        {
            menuItem: 'Source',
            render: () => <TabPane className="parameters-container" attached={false}>
                <div className="table-container">
                    <table className="parameters">
                        <thead>
                            <tr>
                                <th className="col_header parameters-col_name">Name</th>
                                <th className="col_header parameters-col_description">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="parameters-col_name">
                                    <div className="parameter__name required">Pyload<span>&nbsp;*</span></div>
                                </td>
                                <td>
                                    <CodeMirror
                                        minHeight='200px'
                                        basicSetup={{ "lineNumbers": false }}
                                        onContextMenu={handleRightClick}
                                        editable={true}
                                        onChange={onPayloadChange}
                                        theme={monokai}
                                        value={JSON.format(JSON.stringify(payload))}
                                        extensions={[json(), EditorView.lineWrapping]}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </TabPane>,
        },
    ]

    return (
        <Grid columns='equal' className={`swagger-ui ${!collapsed ? 'h-100' : ''}`}>
            <GridRow className='p-10'>
                <GridColumn className={`opblock opblock-${tab.type} ${!collapsed ? 'is-open' : ''}`}>
                    <div className="opblock-summary opblock-summary-get">
                        <button aria-expanded="true" className="opblock-summary-control" onClick={() => onClick && onClick(tab.id - 1)}>
                            <span className="opblock-summary-method">{tab.type?.toUpperCase()}</span>
                            <div className="opblock-summary-path-description-wrapper">
                                <span className="opblock-summary-path  w-100" data-path={path}>
                                    {tab.isCustom && !collapsed ?
                                        <Input className='w-100' icon={'edit'} value={tab.path}  onChange={(e, { value }) => setPath(value)} /> :
                                        <span>{tab.data?.server_url}{path}</span>
                                    }
                                </span>
                                <div className="opblock-summary-description">{tab.data?.summary}</div>
                            </div>
                        </button>
                        {tab.help && (collapsed ? <a className='button tini circular primary icon ui' style={{ position: 'absolute', right: '17px' }} href={tab.help} target='_blanc'>
                            <Icon name='help' />
                        </a> : <Button icon='help' circular primary onClick={() => showHelp(!isHelp)}></Button>)}
                        {onClick && <button className="opblock-control-arrow" onClick={() => onClick(tab.id - 1)} >
                            <Icon name='external square alternate' />
                        </button>}
                    </div>
                    {!collapsed && <div className="opblock-body">
                        <div className="opblock-description-wrapper">
                            <div className="opblock-description">
                                <div className="renderedMarkdown">
                                    <pre style={{ fontFamily: 'unset', fontSize: '1rem' }} dangerouslySetInnerHTML={{ __html: decodeHtml(tab.data?.description) }} />
                                </div>

                            </div>
                        </div>
                        <div className="responses-wrapper" onClick={() => handleRightClick()} >
                            {contextMenu && (
                            <ul className='context-menu'  style={{  top: `${contextMenu.mouseY}px`, left: `${contextMenu.mouseX}px` }}>
                                <Icon  className='absolute right-0' name='close' onClick={() => handleRightClick()} />
                                { dsLib.droppedObjects?.map(el =>
                                    <li key={`o-${el.objectId}`}  onClick={(event) => handleOptionClick(event, el.objectId)} style={{ padding: '5px 10px', cursor: 'pointer' }}>
                                        <Icon name='copy' />
                                        {`${el.displayName}(${el.displayType})`}
                                    </li>)
                                }
                            </ul>
                            )}
                            <Tab className="opblock-section" menu={{ secondary: true, pointing: true }} panes={panes.filter(pan => ('get' !== tab.type || pan.menuItem !== 'Body'))} />
                        </div>
                        <div className="responses-wrapper py-10">
                            <div className="opblock-section-header">
                                <h4>Responses</h4>
                                <Button secondary onClick={runWS} loading={loading} icon labelPosition='left'>
                                    <Icon name='play' /> Execute
                                </Button>
                            </div>
                            <div className="responses-inner">
                                <CodeMirror
                                    basicSetup={{ "lineNumbers": false }}
                                    minHeight='200px'
                                    theme={monokai}
                                    value={typeof response === 'object' ? JSON.format(JSON.stringify(response)) : response}
                                    extensions={[json(), EditorView.lineWrapping]}
                                />
                            </div>
                        </div>
                    </div>}
                </GridColumn>
                {isHelp &&
                    <GridColumn width={6}>
                        <iframe className={`opblock opblock-${tab.type}`} src={tab.help} style={{ width: '100%', height: '100%', border: 0 }} />
                    </GridColumn>}
            </GridRow>
        </Grid>
    );
};

export default WSBlock;

/**
 * Purpose of this file is to provide to React components a context containing 
 * all important informations about the current platform and connected user
 */
import { useState, useRef, useEffect, createContext, useContext } from 'react'
import { loadDsApi, showPopup } from '../lib/widget-utils'
import { isHTML } from '../lib/widget-utils'
import axios from 'axios';
const icons = require.context('../images/icons', true);
const images = require.context('../images', true);
const servers = {
    "{3DCompass}": "/3DSpace",
    "{undefined}": "/3DSpace",
    "{3D Manufacturing Operations Management}": "/3DSpace",
    "{3DPlan}": "/3DPlan",
    "{3DDrive}": "/3DDrive",
    "{3DSpace}": "/3DSpace",
    "{CLM}": "/CLM",
    "{MyService}": "/MyService",
    "{Sourcing}": "/3DSpace",
    "{ConfigRuleSolver}": "/3DSpace",
    "{SemanticGraphIndex}": "/SemanticGraphIndex",
    ".../3drdfpersist/governance": "/3DSpace",
    "{dfs}": "/dfs",
    "{softproducersystemsUrl}": "/3DSpace",
    "{exchange}": "/exchange",
    "{Experiment}": "/Experiment",
    "{UsersGroup}": "/3DSpace",
    "{3DPassport}": "/iam",
    "{Laboratory}": "/3DSpace",
    "{Materials}": "/3DSpace",
    "http://localhost:{port}/3drdfdc": "/3drdfdc",
    "{OrderDataMgmt}": "/OrderDataMgmt",
    "https://{PSL}": "/PSL",
    "SciSearch": "/SciSearch",
    "{3DNetwork}": "/3DNetwork",
    "{3DPortfolio}": "/3DPortfolio",
    "https://{service_authority}{rooturi}": "/3DSpace",
    "https://eu2-supstg-obs.3dx-staging.3ds.com/api/v1/audit": "/api/v1/audit",
    "{Relations}": "/3DSpace"
}

export const DSContextComponent = () => {
    const dsEnv = useRef({})
    const [ready, setReady] = useState(false);
    
    useEffect(() => {
        dsEnv.current.registryUrl = 'https://eu1-registry.3dexperience.3ds.com/api/v1/platform/service/instance';
        dsEnv.current.icons = icons;
        dsEnv.current.images = images;
        dsEnv.current.taggerProxy = null;
        dsEnv.current.droppedObjects = [];
        dsEnv.current.getImage = (name) => name ? images('./' + name + '.png') : undefined;
        dsEnv.current.loadDsApi = loadDsApi;
        dsEnv.current.callDataAsync = callDataAsync;
        dsEnv.current.toUrlParams = toUrlParams;
        dsEnv.current.widget = window.widget;

        async function fetchAuth() {           
            if (!dsEnv.current.url3DCompass) throw ("No 3DCompass defined for this url. Please set correct 3dexperience url on settings ...");
            dsEnv.current.platformId = dsEnv.current.dsTenantId || (await callDataAsync('GET', dsEnv.current.url3DCompass + `/resources/AppsMngt/api/v1/public/registry/info?xrequestedwith=xmlhttprequest`))?.id;
            dsEnv.current.platforms = (await callDataAsync('GET', dsEnv.current.url3DCompass + `/resources/AppsMngt/api/v1/services?cors=true&xrequestedwith=xmlhttprequest`))?.platforms;
            dsEnv.current.version = (await callDataAsync('GET', dsEnv.current.url3DCompass + `/resources/AppsMngt/infos/feature/compass?xrequestedwith=xmlhttprequest`, { headers:{'Content-Type': 'text/plain'} }))?.portfolio?.split('/')?.[3]?.split('.');
            dsEnv.current.release = {year: dsEnv.current.version?.[0], spack: dsEnv.current.version?.[1]};
            dsEnv.current.platform = dsEnv.current.platforms?.length === 1 ? dsEnv.current.platforms[0] : dsEnv.current.platforms.find(p => p.id?.toLowerCase() === dsEnv.current.platformId?.toLowerCase());
            dsEnv.current.services = dsEnv.current.platform?.services;
            dsEnv.current.url3DSpace = dsEnv.current.services?.find(e => e.name === '3DSpace')?.url;
            let parameters = {};
            dsEnv.current.services.map(s => servers[`{${s.name}}`] = s.url);
            try { parameters = JSON.parse(dsEnv.current.widget.getValue("parameters")); } catch (error) { }
            dsEnv.current.servers = { ...servers, ...parameters }
            if (!dsEnv.current.url3DSpace) throw (`No 3DSpace defined for this TenantId ${dsEnv.current.dsTenantId} ...`);
            dsEnv.current.urlRoot = new URL(dsEnv.current.url3DSpace)?.origin;
            dsEnv.current.securityContext = (await callDataAsync('GET', dsEnv.current.url3DSpace + '/resources/pno/person/getsecuritycontext'))?.SecurityContext;
            dsEnv.current.csrf = (await callDataAsync('GET', dsEnv.current.url3DSpace +  '/resources/v1/application/CSRF'))?.csrf.value;
            return true;
        }

        if (window?.widget?.id) {
            loadDsApi('DS/i3DXCompassPlatformServices/i3DXCompassPlatformServices')
                .then(async i3DXCompassPlatformServices => {
                    async function getServiceUrl() {
                        return new Promise((resolve, reject) => {
                            try {
                                i3DXCompassPlatformServices.getServiceUrl({
                                    serviceName: '3DCompass',
                                    platformId: window.parent.dsTenantId || 'OnPremise',
                                    onComplete: (result) => {
                                        resolve(result); // Resolve the promise when the operation is successful
                                    },
                                    onFailure: () => {
                                        reject(new Error('Error while retrieving 3DSpace URL')); // Reject the promise on failure
                                    }
                                });
                            } catch (error) {
                                //resolve('/3DSpace');
                                reject(new Error('Error while retrieving 3DSpace URL'));
                                console.warn('Error while retrieving 3DSpace URL, using default value');
                            }
                        });
                    }
                    dsEnv.current.url3DCompass = await getServiceUrl();
                    dsEnv.current.dsTenantId = window.parent.dsTenantId;
                    //dsEnv.current.services = await getPlatformServicesAsync();
                }).finally(async () => {
                    dsEnv.current.WAFData = await loadDsApi('DS/WAFData/WAFData');
                    // DS drag and drop APIS
                    dsEnv.current.dnd = await loadDsApi('DS/DataDragAndDrop/DataDragAndDrop');
                    await fetchAuth();
                    setReady(true)
                });
        } else {
            dsEnv.current.url3DCompass = localStorage.getItem('uwa_preferences_host');
            dsEnv.current.dsTenantId = localStorage.getItem('uwa_preferences_platformId');                      
            fetchAuth().catch(err => {
                if (err?.data?.error === 'invalid_grant') {
                    let redirect = err?.data?.x3ds_auth_url?.split('service=');
                    let host = localStorage.getItem('uwa_preferences_host');
                    let new_url = encodeURIComponent(window.location.href?.split('?')?.[0] + '?' + decodeURIComponent(redirect?.[1]).split('?')?.[1]);
                    let url = redirect[0] + 'service=' + new_url;
                    showPopup(`<p>
                            ${err?.data?.error_description}
                            <br/>
                            <a target="_blanc" class="ui primary button" href="${err?.data?.x3ds_auth_url}"> Login </a>
                        </p>`);
                } else
                    showPopup(err?.data?.error?.message || err?.data?.error || err?.data?.message ||  err?.data || err.message || "" + err);

            }).finally(async () => {
                // DS drag and drop APIS
                dsEnv.current.dnd = await loadDsApi('DS/DataDragAndDrop/DataDragAndDrop');
                setReady(true);
            });
        }
    }, [])

    const callDataAsync = function (method, uri, payload = { headers: {}, body: {}, query: {} }) {
        if (dsEnv.current.csrf) payload.headers.ENO_CSRF_TOKEN ??= dsEnv.current.csrf;
        if (dsEnv.current.securityContext) payload.headers.SecurityContext ??= encodeURIComponent(dsEnv.current.securityContext);

        return new Promise((resolve, reject) => {
            callData(method, uri, payload,
                (result) => {
                    resolve(result);
                },
                (error) => {
                    reject(error);
                });
        });
    }

    function toUrlParams(payload) {
        return Object.entries(payload)
            ?.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            ?.join('&');
    }

    function isJsonRequest(headers) {
        return headers['Content-Type']?.indexOf('application/json') !== -1;
    }


    const callData = function (method, url, { query, body, headers }, cbSuccess, cbFailure) {
        let resourceURI = url;

        let headerWAF = {
            "Accept": isJsonRequest(headers) ? "application/json;charset=UTF-8" : "text/plain",
            "Content-Type": "application/json;charset=UTF-8",
            ...headers
        };

        let methodWAF = method;
        let options = {
            method: methodWAF,
            headers: headerWAF,
        };

        if (!window.widget?.id) {
            //options.mode = 'no-cors';
            options.credentials = 'include';
        }

        if ('get' !== methodWAF?.toLowerCase())
            options['body'] = isJsonRequest(headerWAF) ? JSON.stringify(body) : body;

        if (query) {
            let url = toUrlParams(query, resourceURI);
            if (url) resourceURI = `${resourceURI}?${url}`;
        }

        // Send the request using fetch
        fetch(resourceURI, options)
            .then(response => {
                // Always try to parse the response as JSON
                return response.text().then(data => {
                    const isJson = JSON.isValid(data);
                    const result = isJson ? JSON.parse(data) : data;
                    const isHtml = !isJson && isHTML(data);
                    if (!response.ok || isHtml) {
                        // If the response is not ok, throw an error with the data included
                        throw { status: response.status, ishtml: isHtml, data: result };
                    }
                    return result;  // If successful, return the parsed data
                });
            })
            .then(dataResp => {
                // Call the success callback
                if (cbSuccess) {
                    cbSuccess(dataResp);
                }
            })
            .catch(error => {
                if (cbFailure) {
                    cbFailure(error);
                }
            });
    }

    return ready ? dsEnv.current : null
}


const DSContext = createContext(DSContextComponent)
export default DSContext

export const useDSContext = () => {
    const dsLib = useContext(DSContext)
    return dsLib
}

import React from 'react';
import { Segment } from 'semantic-ui-react';
import './css/style.css';
import './css/3ds-icons.css';
import MainFrame from './Component/MainFrame';

function App() {
  return (
    <div className="App">
      <Segment>
        <MainFrame />
      </Segment>
    </div>
  );
}

export default App;

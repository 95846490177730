import React, { useEffect, useState, useRef,  forwardRef} from 'react';
import '../css/swagger-ui.css';
import CodeMirror, { EditorView } from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import { monokai, monokaiInit } from '@uiw/codemirror-theme-monokai';
import { useDSContext } from './DSContext.jsx';
import { Input, Button } from 'semantic-ui-react';
import { generateExampleFromSchema } from '../lib/widget-utils'

const ParamBlock = forwardRef(({param, payload, handleInputChange, handleRightClick}, ref) => {
    const name = param?.name;
    const isRefParam = param?.schema?.properties;
    const dsLib = useDSContext();

    const pattern = /(SecurityContext|CSRF)/;

    const getDefaultValue = (name) => {
        switch (true) {
            case name === 'SecurityContext':
                return dsLib.securityContext;
            case name?.includes('CSRF'):
                return dsLib.csrf;
            default:
                return payload[name];
        }
    };

    const generateValue = () => {
       handleInputChange({target: {name, value: generateExampleFromSchema(param?.schema)}})
    };

    return param && (
        <tr key={`${name}`} data-param-name="SecurityContext" data-param-in="header">
            <td className="parameters-col_name">
                <div className={`parameter__name mw-200px ${param.required ? "required" : ""}`}>
                    {name}
                    {param.required && <span>&nbsp;*</span>}
                </div>
                <div className="parameter__type">{param.schema?.type}</div>
                <div className="parameter__in">({param.in})</div>
            </td>
            <td className="parameters-col_description">
                <div className="renderedMarkdown">
                    <p>{param.description}</p>
                    { isRefParam && <Button icon='add' onClick={generateValue} />}
                </div>
                {param.schema?.enum ? (
                    <>
                        <div className="parameter__enum renderedMarkdown">
                            <p><i>Available values</i> : {param.schema?.enum?.join()}</p>
                        </div>
                        <select
                            name={name}
                            onChange={handleInputChange}
                        >
                            <option key='op-00' value="">--</option>
                            {param.schema?.enum?.map((el, indx) => <option key={`op-${indx + 1}`} value={el}>{el}</option>)}
                        </select>
                    </>
                ) : (
                    isRefParam ? <CodeMirror
                        minHeight='60px'
                        maxWidth='700px'
                        basicSetup={{ "lineNumbers": false }}
                        onContextMenu={handleRightClick}
                        editable={true}
                        theme={monokai}
                        onChange={(value, viewUpdate) => handleInputChange({ target: { name, value: JSON.parse(value) } })}
                        value={JSON.format(JSON.stringify(getDefaultValue(name)))}
                        extensions={[json(), EditorView.lineWrapping]}
                    /> : <div ref={ref}>
                        <Input
                            key={name}
                            style={{ minWidth: 500 }}
                            list={`${name}_objects`}
                            onChange={(e, { value }) => handleInputChange({ target: { name, value } })}
                            name={name}
                            value={getDefaultValue(name)}
                            placeholder={name}
                        />
                        <datalist id={`${name}_objects`}>
                            {dsLib.droppedObjects?.map(el =>
                                <option key={`o-${el.objectId}`} value={el.objectId}>{`${el.displayName}(${el.displayType})`}</option>)
                            }
                        </datalist>
                    </div>
                )}
            </td>
        </tr>
    );
});

export default ParamBlock;

import React, { useEffect, useState } from 'react';
import { Accordion, Icon, Card, Button, Grid, Label } from 'semantic-ui-react';
import { useDSContext } from './DSContext.jsx';

const Home = ({ openWS }) => {
  const dsLib = useDSContext();
  const [data, setData] = useState(null);
  const [searchQuery, setSearchQuery] = useState([]);
  const [selectedReleases, setSelectedRelease] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0); 
  //  Tracks active accordion panels for years
  const base_url = dsLib?.widget?.uwaUrl || window.location.href.split('?')[0];
  let TagNavigatorProxy, taggerProxy; 

  let docUrl = dsLib?.widget?.getValue('docUrl') || 'media';
  
  if (typeof crypto.randomUUID === 'undefined') {
    crypto.randomUUID = () => {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0;
        const v = (c === 'x') ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
  }
  
  // Fetch JSON data from the server
  const filterPublicAPIs = (data) => {
    // Iterate over each key-value pair in the main object
    const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
      // Iterate over each API group (e.g., "CAA3DDriveWSPro")
      const filteredGroups = Object.entries(value).reduce((groupAcc, [groupKey, groupValue]) => {
        // Filter the array of objects based on "x-ds-type"
        const publicItems = groupValue.filter((item) => item["x-ds-type"] === "Public");
        if (publicItems.length > 0) {
          groupAcc[groupKey] = publicItems; // Add the filtered group if it has public items
        }
        return groupAcc;
      }, {});
      if (Object.keys(filteredGroups).length > 0) {
        acc[key] = filteredGroups; // Add the filtered groups if any exist
      }
      return acc;
    }, {});
  
    return filteredData;
  };
  //filter Internal WS
  let filteredData = !data || docUrl !== 'media' ? data : filterPublicAPIs(data);

  const fetchData = async () => {
    try {
      const response = await fetch(`${base_url}/../data/all_openapi_info.json`); // Adjust to your JSON path
      let json = await response.json();
      setData(json);
      if(window.widget.id)
        initTagger();     
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if(dsLib?.widget?.id)
      setTags();

    if(dsLib?.release?.year)
      setActiveIndex(Object.keys(data || []).findIndex(e => e?.contains(dsLib.release?.year)));
  }, [data]);

  function onFilterSubjectsChange(eventFilter) {
    var arrResult = [];
    var arrSubjects = eventFilter.filteredSubjectList;
    var ds6w_release = eventFilter.allfilters['ds6w:what/Release'] || [];
    var ds6w_type = eventFilter.allfilters['ds6w:what/Type'] || [];
    var ds6w_keywords = eventFilter.allfilters['ds6w:what/Keywords'] || [];
    var ds6w_apps = eventFilter.allfilters['ds6w:what/Applications'] || [];
    if(ds6w_release) setSelectedRelease(ds6w_release?.map(e => e.object))
      if(ds6w_type) setSelectedTypes(ds6w_type?.map(e => e.object))
        setSearchQuery([...searchQuery, ...ds6w_keywords?.map(e => e.object), ...ds6w_apps?.map(e => e.object)]);
    }
    
  function onSearchWidget(_searchQuery) {
      setSearchQuery([...searchQuery, ..._searchQuery.split(' ')]);
    }
  function onResetSearchWidget() {
      setSearchQuery([]);
    }
    
  React.useEffect(() => {
      if (dsLib?.widget?.id) {
        dsLib?.loadDsApi("DS/TagNavigatorProxy/TagNavigatorProxy").then(tagNavigatorProxy => {
          TagNavigatorProxy = tagNavigatorProxy;
          fetchData();
          dsLib.widget.addEvent("onSearch", onSearchWidget);
          dsLib.widget.addEvent("onResetSearch", onResetSearchWidget);
      });     
    } else if (dsLib?.widget)  {
      fetchData();
    }
  }, [dsLib?.widget]);

  function initTagger() {
    if (!taggerProxy && TagNavigatorProxy) {
      let options = {
        widgetId: dsLib?.widget?.id,
        filteringMode: "WithFilteringServices"
      };
      taggerProxy = TagNavigatorProxy.createProxy(options);
      taggerProxy.addEvent("onFilterSubjectsChange", onFilterSubjectsChange);
      dsLib.taggerProxy = taggerProxy;
      setTags();
    }
  }

  function setTags() {
    let tags = {}; // Clear tags
  
    // Get the list of all releases (keys of 'data')
    let arrData = Object.keys(filteredData || {});
    
    // Iterate through each release
    for (let i = 0; i < arrData.length; i++) {
      let release = arrData[i];
      let uId = crypto.randomUUID(); // Generate unique ID for release
      tags[uId] = []; // Initialize the tag for this release
      
      // Add release data
      tags[uId].push({
        object: release,
        sixw: "ds6w:what/Release",
        dispValue: release.replace('3DEXPERIENCE', '3DEXPERIENCE ')
      });
  
      // Get applications for the current release
      let applications = Object.entries(data[release]);
      
      // Iterate through each application
      for (let j = 0; j < applications.length; j++) {
        let uId_1 = uId; //crypto.randomUUID(); // Generate unique ID for each application
        let app = applications[j][1][0]; // Get the first application object
        let tag = applications[j][0]; // Get the tag for this application
        let services = app["x-ds-service"] || []; // Get the services associated with the application
        let type = app["x-ds-type"];
        // Initialize tags[uId_1] if it doesn't exist yet
        if (!tags[uId_1]) {
          tags[uId_1] = [];
        }
  
        // Add the tag for the application
        tags[uId_1] = [
          ...tags[uId_1], 
          {
            object: type,
            sixw: "ds6w:what/Type",
            dispValue: type
          },
          {
            object: tag,
            sixw: "ds6w:what/Keywords",
            dispValue: tag.replace(/CAA/, "").replace(/WS/, "")
          },
          ...(services?.map ? services?.map(e => ({
            object: e,
            sixw: "ds6w:what/Applications",
            dispValue: e
          })) : []) // Ensure services is an array before mapping
        ];
      }
    }
  
    // Assuming `dsLib?.taggerProxy?.setSubjectsTags(tags)` is the function to save the tags
    dsLib?.taggerProxy?.setSubjectsTags(tags);
  }
  
  // Toggle the active accordion panel for each year
  const handleYearClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
      {filteredData ? (
        <Accordion styled fluid>
          {Object.keys(filteredData).filter(e => !selectedReleases?.length || selectedReleases?.includes(e)).map((yearKey, yearIndex) => (
            <React.Fragment key={yearKey}>
              {/* Year Accordion Panel */}
              <Accordion.Title
                active={activeIndex === yearIndex}
                index={yearIndex}
                onClick={() => handleYearClick(yearIndex)}
              >
                <Icon name='dropdown' />
                {yearKey}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === yearIndex}>
                <Accordion styled fluid>
                  <Card.Group>
                    {/* Folder Accordion Panels within each Year */}
                    {Object.keys(filteredData[yearKey]).map((folderKey, folderIndex) => (
                      <React.Fragment key={folderKey}>
                        {/* Cards for each API info item in the folder */}
                        {filteredData[yearKey][folderKey].map((info, infoIndex) => {
                            let base_doc_url = (docUrl === 'dsdoc')  ? `https://dsdoc.dsone.3ds.com/devdoc/${yearKey}/en/English/` : `https://media.3ds.com/support/documentation/developer/${yearKey.match(/R\d{4}x/)?.[0]}/en/English/`;
                            let helpUrl = `${base_doc_url}${info.source_url?.split('.openapi.json')?.[0]}.htm`;
                            let check = !searchQuery?.length || searchQuery.some(query => JSON.stringify(info).toLowerCase().indexOf(query?.toLowerCase()) !== -1);
                            let checkType = !selectedTypes?.length || selectedTypes?.includes(info["x-ds-type"]);
                            let opapi_base_url = `${base_url}/../data/JSON/${yearKey}/`
                            return check && checkType && <Card key={`${folderKey}-item-${infoIndex}`}>
                                <Card.Content header={info.title} />
                                { 'Internal' === info["x-ds-type"] && <Label color='blue' corner='right'>
                                  <Icon name='lock'/>
                                </Label>}
                                <Card.Content description={<div
                                    dangerouslySetInnerHTML={{
                                        __html: (info.description?.length < 150) ? info.description : info.description?.slice(0, 150) + "...",
                                    }}
                                />} />
                                <Card.Content extra>
                                    <Button
                                        primary circular
                                        icon='external'
                                        onClick={() => openWS({ id: crypto.randomUUID(), url: `${opapi_base_url}${info.source_url}`, help: helpUrl, title: info.title })}
                                    >
                                    </Button>
                                    <a className='button circular icon ui'
                                        href={helpUrl} target='_blanc'>
                                        <Icon name='help' />
                                    </a>
                                </Card.Content>
                            </Card>
                        })}
                      </React.Fragment>
                    ))}
                  </Card.Group>
                </Accordion>
              </Accordion.Content>
            </React.Fragment>
          ))}
        </Accordion>
      ) : (
        <p>Loading data...</p>
      )}
    </div>
  );
};

export default Home;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import {
  TabPane, Grid, Button, Icon, Label, Message, CardContent, Form, FormField, Checkbox,
  Segment, Popup, Sidebar, Header, Container, Dimmer, CardGroup,
  Loader, Image, Card, Tab, Menu, Input, CardMeta, CardHeader, CardDescription
} from 'semantic-ui-react';
import { useDSContext } from './DSContext.jsx';
import OpenAPIBlock from './OpenAPIBlock.jsx';
import WSBlock from './WSBlock.jsx';
import { getUsername, getSecurityContext, showPopup } from '../lib/widget-utils.js';
import Home from './Home.jsx';

function MainFrame() {

  const [dropOpen, setDropOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const dsLib = useDSContext();

  const tabsRef = useRef(tabs);

  const closeTab = (event, tabId, tabIndex) => {
    event.stopPropagation();
    event.preventDefault();
    tabs[tabIndex].deleted = true;
    //tabs.splice(tabIndex, 1);
    setTabs([...tabs]);
    if (activeTab * 1 === (tabIndex + 1)) {
      setActiveTab(Math.max(0, (activeTab * 1) - 1));
    } // Switch to the first tab if the active tab was closed  
  };

  const handleTabChange = (e, { activeIndex }) => {
    setActiveTab(activeIndex);
    if (dsLib?.widget?.getValue('save') === true)
      saveSession();
  }

  const openWS = (tab) => {
    setTabs([...tabs, tab]);
    setActiveTab(tabs.length + 1);
  }

  const saveSession = () => {
    localStorage.setItem('sessionJson', JSON.stringify(tabs.filter(e => !e.deleted)));
  }

  const saveFile = () => {
    const content = JSON.format(JSON.stringify(tabs.filter(e => !e.deleted)));
    const fileName = `WGT-BRUNO_${new Date().toISOString().replace(/[:.-]/g, '')}.json`;

    // Create a Blob from the string content
    const blob = new Blob([content], { type: 'text/plain' });

    // Create an anchor element to trigger the file download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);  // Create an object URL for the Blob
    link.download = fileName;  // Set the name of the file to download

    // Programmatically click the link to start the download
    link.click();
  };

  const showInfo = () =>{
    showPopup(`<table border=1>
      ${Object.entries(dsLib?.servers || {}).map(s => `<tr><td><b>${s[0]}: </b></td><td>${s[1]}</i></td></tr>`).join('')}
      </tables>`);
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected and if it is a JSON file
    if (file && file.type === 'application/json') {
      const reader = new FileReader();

      reader.onload = () => {
        try {
          // Parse the content of the file as JSON
          const parsedData = JSON.parse(reader.result);
          setTabs([...tabs, ...parsedData]);
        } catch (error) {
          alert('Failed to parse the file content as JSON.');
        }
      };

      reader.readAsText(file);  // Read the file as text
    } else {
      alert('Please select a valid JSON file.');
    }
  };

  const addDraggedObjects = async (objects) => {
    const newObjects = objects.filter(obj => !dsLib.droppedObjects?.some(droppedObj => droppedObj.objectId === obj.objectId));
    let newInstances = await Promise.all(newObjects.map(async obj => {
      try {
        let res = await dsLib.callDataAsync('get', `${dsLib.url3DSpace}/resources/dsmfg/private/V0/invoke/whereUsed?pid=${obj.objectId}`);
        let inst = res?.[0]?.attributesValue;
        return { objectId: inst.PID, displayName: inst['PLMInstance.PLM_ExternalID'], displayType: inst.type }
      } catch (error) { }
    }));
    dsLib.droppedObjects = [...newObjects, ...newInstances.filter(Boolean), ...dsLib.droppedObjects];
  };

  function makeDropable() {
    let wait;
    if (!dsLib?.widget) return
    dsLib.dnd?.droppable(dsLib.widget.body, {
      counter: 0,
      drop: async function (res) {
        //setLoading(true);
        let results = JSON.parse(res);
        await addDraggedObjects(results.data?.items);
        setDropOpen(false);
      },
      over: function (i, e) {
        clearTimeout(wait);
        setDropOpen(true);
      },
      enter: function (i, e) {
        clearTimeout(wait);
        setDropOpen(true);
      },
      leave: function (i) {
        wait = setTimeout(() => setDropOpen(false), 500);
      }
    });
  }

  function onRefresh() {
    //setThumbs([]);
    //setPage(0);
  }

  function onLoad() {
    try {
      if (dsLib.widget) {

        if (!dsLib.widget?.getValue('ctx'))
          localStorage.setItem('uwa_preferences_ctx', dsLib.securityContext)

        if (!dsLib.widget?.id) {
          window.widget.preferences.push({
            "name": "host",
            "type": "string",
            "label": "3DEXPERIENCE (Any URL or TenentId)",
            "defaultValue": "`https://vdemopro1102dsy.extranet.3ds.com`"
          });      
          // if cloud
          if(dsLib.platforms?.length)
            window.widget.preferences.push({
              "name": "platformId",
              "type": "list",
              "label": "Liste of Cloud Tenants",
              "options": dsLib.platforms?.map(p => ({"value":p.id, "label": p.name}))
            });
        }

        // when local save prefs
        if (!window.widget?.id)
          window.widget.preferences.map(p => window.widget.setValue(p.name,  localStorage.getItem(`uwa_preferences_${p.name}`)));

        if (dsLib.widget.getValue('save') === true)
          setTabs(JSON.parse(localStorage.getItem('sessionJson')) || []);
      }
    } catch (error) {
      setTabs([]);
    }
  }

  function endEdit(event) {
    //reftesh ui
    // when local read prefs
    if (!window.widget?.id) {
      const host = window.widget?.getValue('host');
      const curHost = localStorage.getItem(`uwa_preferences_host`);
      const curTenent = localStorage.getItem(`uwa_preferences_platformId`);
      const newTenent = window.widget?.getValue('platformId');
      // in case no http it's a tenent id
      let dsTenantId = curTenent;
      let url3DCompass = host;
      window.widget.preferences.map(p => {if(window.widget?.getValue(p.name) !== '') localStorage.setItem(`uwa_preferences_${p.name}`, window.widget?.getValue(p.name))});
      if (host?.includes("3dexperience.3ds.com") || !host?.startsWith('http')) {
         if(curTenent !== newTenent) dsTenantId = newTenent;
         if(host !== curHost || !dsTenantId) dsTenantId = host?.match(/\/\/([a-zA-Z]*[0-9]+)[^\/]*\.3dexperience\.3ds\.com/)?.[1] || host;
         dsLib.callDataAsync('GET', `${dsLib.registryUrl}?serviceId=3DCompass&platformId=${dsTenantId}`).then(res => {
          url3DCompass = res?.[0]?.services?.[0]?.url || host;
          localStorage.setItem(`uwa_preferences_host`, url3DCompass);
         }).finally(()=>{
           localStorage.setItem(`uwa_preferences_platformId`, dsTenantId?.toUpperCase());
           window.location.reload();
         });
      } else
        window.location.reload();
    }

    setTabs([...tabsRef.current]);
  }

  useEffect(() => {
    tabsRef.current = tabs;
  }, [tabs]);

  React.useEffect(() => {
    if (dsLib?.widget) {
      makeDropable();
      dsLib.widget?.addEvents({
        'onRefresh': onRefresh,
        'onLoad': onLoad,
        'endEdit': endEdit
      });
    }
  }, [dsLib?.widget]);

  const NewWSForm = () => {
    const [url, setURL] = useState(false);

    return (<>{
      ["get", "post", "patch", "put", "delete"].map((e, indx) => {
        let tb = { id: crypto.randomUUID(), type: e, title: `${e?.toUpperCase()} Web Service`, isCustom: true };
        return <WSBlock key={indx} tab={tb} collapsed={true} onClick={() => openWS(tb)} />;
      })}
    </>)
  }

  let home = [{
    menuItem: (
      <Menu.Item key='m-home'>
        <Icon className='btn-r' name='home' /> Home
      </Menu.Item>
    ),
    pane:
      <TabPane key={`pan-home-00`}>
        <Home openWS={openWS} />
      </TabPane>
  }];

  const panes = [...home, ...tabs.map((tab, indx) => ({
    menuItem: (
      <Menu.Item className={`item-${tab.type}`} key={`m-${indx + 1}`} style={{ display: tab.deleted ? 'none' : '' }}>
        {tab.title}
        <Icon name="close" onClick={(e) => closeTab(e, tab.id, indx)} />
      </Menu.Item>
    ),
    pane: <TabPane key={`pan-${indx + 1}`} style={{ display: tab.deleted ? 'none' : '' }}>
      {tab.url ?
        <OpenAPIBlock url={tab.url} addTab={openWS} help={tab.help} /> :
        <WSBlock tab={tab} collapsed={false} />
      }
    </TabPane>
  }))];

  return (
    <>
      <Dimmer active={loading} page>
        <Loader>Loading</Loader>
      </Dimmer>
      <Dimmer id='dropzone' active={dropOpen} inverted>
        <Header icon color='blue'>
          <Image src={dsLib?.getImage('3DPart')} />
          Drop here <br />
          Ref and Instance Ids will be added to every parameter Dropdown List !
        </Header>
      </Dimmer>
      <div>
        <Container fluid>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Tab
                  className='tab'
                  activeIndex={activeTab}
                  renderActiveOnly={false}
                  menu={{ secondary: true, pointing: true }}
                  onTabChange={handleTabChange}
                  panes={panes}
                />
                <div className='menu floating' >
                  <Popup content={<NewWSForm />}
                    on='click'
                    trigger={<Button secondary circular icon='plus' />}
                  />
                  <Popup className='transparent' content={<>
                    <input
                      type='file'
                      id='fileInput'
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor='fileInput' className='ui icon circular button'>
                      <Icon name='folder open' />
                    </label>
                    <Button secondary circular icon='save' onClick={saveFile} />
                    <Button secondary circular icon='info' onClick={showInfo} />
                  </>}
                    on='click'
                    trigger={<Button secondary circular className='floating' icon='tasks' />}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default MainFrame;

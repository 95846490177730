/* eslint-disable max-len */
/* eslint-disable no-multi-assign */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable react/jsx-filename-extension */
const pathWAFData = 'DS/WAFData/WAFData';
const pathi3DXCompassServices = 'DS/i3DXCompassServices/i3DXCompassServices';

let securityContext;
let url3DSpace;
let urlServiceExt;

const regex = [
  {
    re: /<a href="(.*)">(.*)<\/a>/,
    // eslint-disable-next-line react/react-in-jsx-scope
    replace: (r, str) => <a href={str.match(r)[1]}>{str.match(r)[2]}</a>,
  },
  {
    re: /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}\+\d{4}/,
    replace: (r, str) => (new Date(str)).toLocaleString(),
  },
];

const loadDsApi = (anyAPI) => {
  // eslint-disable-next-line no-restricted-globals
  if (window.parent?.[anyAPI]) {
    return new Promise((resolve) => {
      resolve(window.parent[anyAPI]);
    });
  }
  return new Promise((resolve) => {
    // eslint-disable-next-line no-restricted-globals
    window.parent.require([anyAPI], (api) => {
      resolve(api);
    });
  });
};

const getSpaceUrl = () => new Promise((resolve, reject) => {
  loadDsApi(pathi3DXCompassServices).then((i3dx) => i3dx.getServiceUrl({
    serviceName: '3DSpace',
    platformId: 'OnPremise',
    onComplete(url) {
      url3DSpace = url;
      console.log(`getSpaceUrl SUCCESS ==> ${url}`);
      resolve(url3DSpace);
    },
    onFailure(error) {
      console.log(`getSpaceUrl FAILURE==> ${error.message}`);
      reject(error);
    },
  }));
});

const getServiceExtUrl = () => {
  const windowUrl = window.location.href.split(/:[0-9]{2,4}/)[0];
  const serviceExt = `https://service-ext.${windowUrl.substring(windowUrl.indexOf('.') + 1)}:9090/`;
  urlServiceExt = serviceExt;
  return serviceExt;
};

const getWidgetUrl = (w = null) => {
  const windowUrl = (w === null ? window : w).location.href.split(/:[0-9]{2,4}/)[0];
  const widgetUrl = `https://widget.${windowUrl.substring(windowUrl.indexOf('.') + 1)}:9090/`;
  return widgetUrl;
};

const getUsername = () => new Promise((resolve, reject) => {
  getSpaceUrl().then((spaceUrl) => {
    const pathWS = `${spaceUrl}/resources/modeler/pno/person?current=true&select=preferredcredentials&select=collabspaces&tenant=OnPremise`;
    loadDsApi(pathWAFData).then((wafdata) => wafdata.authenticatedRequest(pathWS, {
      metho: 'GET',
      type: 'json',
      onComplete(data) {
        resolve(data.name);
      },
      onFailure(error) {
        console.log(`getUsernameFAILURE==> ${error.message}`);
        reject(error);
      },
    }));
  });
});

// eslint-disable-next-line no-async-promise-executor
const getSecurityContext = () => new Promise(async (resolve, reject) => {
  let pathWS = `${url3DSpace ?? await getSpaceUrl()}/resources/modeler/pno/person?current=true`;
  pathWS += '&select=preferredcredentials&select=collabspaces';
  pathWS += '&tenant=OnPremise';
  loadDsApi(pathWAFData).then((wafdata) => wafdata.authenticatedRequest(pathWS, {
    method: 'GET',
    type: 'json',
    onComplete(data) {
      const role = data.preferredcredentials.role.name;
      const organization = data.preferredcredentials.organization.name;
      const collabspace = data.preferredcredentials.collabspace.name;
      securityContext = `ctx::${role}.${organization}.${collabspace}`;
      console.log(`SUCCESS==> ${securityContext}`);

      resolve(securityContext);
    },
    onFailure(error) {
      console.log(`getSecurityContext FAILURE==> ${error.message}`);
      reject(error);
    },
  }));
});

// eslint-disable-next-line no-async-promise-executor
const getObjectInfo = (objectId) => new Promise(async (resolve, reject) => {
  const path3DspaceWS = `${url3DSpace ?? await getSpaceUrl()}/resources/v1/collabServices/attributes/op/read?tenant=OnPremise&xrequestedwith=xmlhttprequest`;
  const dataWS = `{"lIds":["${objectId}"],"plmparameters":"false","attributes":"true","navigateToMain":"false","debug_properties":""}`;
  loadDsApi(pathWAFData).then(async (wafdata) => wafdata.authenticatedRequest(path3DspaceWS, {
    method: 'POST',
    type: 'json',
    proxy: 'passport',
    headers: {
      Accept: 'application/json;charset=UTF-8',
      SecurityContext: securityContext ?? await getSecurityContext(),
      'Content-Type': 'application/json;charset=UTF-8',
      'X-DS-CSRFTOKEN': 'DS-Request-ID,X-DS-CSRFTOKEN',
    },
    data: dataWS,
    onComplete(data) {
      // eslint-disable-next-line no-undef
      const vName = UWA.Json.path(data, '$..*[?(@.selectable=="attribute[PLMEntity.V_Name]")].value');
      /* AHO */
      // eslint-disable-next-line no-undef
      const engTitle = UWA.Json.path(data, '$..*[?(@.selectable=="attribute[PLMEntity.V_description]")].value');
      const paramData = {};
      paramData.objectId = objectId;

      if (vName && vName.length) {
        // eslint-disable-next-line prefer-destructuring
        paramData.vName = vName[0][0];
      }

      /* AHO */
      if (engTitle && engTitle.length) {
        // eslint-disable-next-line prefer-destructuring
        paramData.engTitle = engTitle[0][0];
      }

      paramData.sc = securityContext;

      resolve(paramData);
    },
    onFailure(error) {
      // eslint-disable-next-line no-undef
      UWA.log(` read FAILURE==> ${error.message}`);
      reject(error);
    },
  }));
});

/* Call the 3DSpace webservice getObjectInfo to get cadnumber */
const getEINFromSpace = (id) => new Promise((resolve) => {
  getObjectInfo(id).then((data) => {
    resolve(data.vName);
  });
});

const getCadNumber = (id) => new Promise((resolve) => {
  getEINFromSpace(id).then((cadnumber) => {
    console.log(`cad number: ${cadnumber} for physicalId ${id}`);
    resolve(cadnumber);
  });
});

const getProgramFromFirstLetter = (cadnumber) => {
  if (cadnumber[0] === 'V' || cadnumber[0] === 'W') return 'A350';
  if (cadnumber[0] === 'D' || cadnumber[0] === 'E') return 'A320';
  return 'A350';
};

const getPDMType = (objectType, cadnumber, program = getProgramFromFirstLetter(cadnumber)) => new Promise((resolve) => {
  let PDMType;
  let path = `${urlServiceExt ?? getServiceExtUrl()}widget-core-3dxc/rest/3dxc/getLLFunctionalType?cadnumber=${cadnumber}&program=${program}`;

  if (objectType) {
    path = `${path}&objectType=${objectType}`;
  }

  loadDsApi(pathWAFData).then(async (wafdata) => wafdata.authenticatedRequest(path, {
    method: 'GET',
    async: false,
    onComplete(data) {
      const dataJson = JSON.parse(data);

      if (dataJson && dataJson.length > 0) {
        PDMType = dataJson[0].functional_type;
        PDMType = PDMType.replace('ADAP-', '');
        PDMType = PDMType.replace('ADF-', '');
        PDMType = PDMType.replace('SAM-', '');
        PDMType = PDMType.replace('CADNODE', 'ASSY');

        console.log(`PDMType for ${cadnumber}found: ${PDMType}`);
        resolve(PDMType);
      } else {
        resolve(null);
        console.log(`No PDMType found in NODES_LL (might be an upper level) for ${cadnumber}`);
      }
    },
    onFailure(error) {
      console.log(error);
      console.log(`No PDMType found in NODES_LL (might be an upper level) for ${cadnumber}`);
    },
  }));
});

const loadPlatformApi = () => loadDsApi('DS/PlatformAPI/PlatformAPI');

const getPDMInformation = (cadnumber, objectType, program) => new Promise((resolve, reject) => {
  let path = `${getServiceExtUrl()}widget-core-3dxc/rest/3dxc/getPDMNumber?cadnumber=${cadnumber}`;

  if (objectType) {
    path = `${path}&objectType=${objectType}`;
  }
  if (program) {
    path = `${path}&program=${program}`;
  }

  loadDsApi(pathWAFData).then(async (wafdata) => wafdata.authenticatedRequest(path, {
    method: 'GET',
    onComplete(data) {
      const dataJson = JSON.parse(data);

      if (dataJson && dataJson.length > 0) {
        const pDMNumber = dataJson[0].PDMNumber;
        const pDMType = dataJson[0].PDMType;

        console.log(`Received PDM Number: ${pDMNumber} and type ${pDMType} for cadnumber: ${cadnumber}`);
        resolve(dataJson[0]);
      } else {
        resolve(null);
        console.log(`No PDMNumber found for: ${cadnumber}`);
      }
    },
    onFailure(error) {
      console.log(error);
      console.log(`No PDMNumber found for: ${cadnumber}`);
      reject(error);
    },
  }));
});

const getEngTitle = (id) => new Promise((resolve) => {
  getObjectInfo(id).then((data) => {
    resolve(data.engTitle);
  });
});

/* Call widget-core-3dxc to get Lower Level Attributes */
const getLowerLevelAttributes = (tabInfo, cadnumber, program = getProgramFromFirstLetter(cadnumber)) => new Promise((resolve) => {
  let path = `${urlServiceExt ?? getServiceExtUrl()}widget-core-3dxc/rest/3dxc/${tabInfo.webservice}?cadnumber=${cadnumber}&program=${program}`;

  if (tabInfo.objectType) path = `${path}&objectType=${tabInfo.objectType}`;

  console.log(`widget-core request : ${path}`);

  loadDsApi(pathWAFData).then(async (wafdata) => wafdata.authenticatedRequest(path, {
    method: 'GET',
    headers: {
    },
    onComplete(data) {
      const dataJson = JSON.parse(data);
      resolve(dataJson);
    },
    onFailure() {
      console.log(`No data for ${cadnumber}/${tabInfo.objectType}`);
    },
  }));
});

const getCloudviewUrl = () => {
  const windowUrl = window.location.href.split(/:[0-9]{2,4}/)[0];
  if (windowUrl.includes('3dxfactory-dev')) return getWidgetUrl();
  return `https://cv.${windowUrl.substring(windowUrl.indexOf('.') + 1)}:9090/`;
};

const getDashboardUrl = () => window.location.href.split(/:[0-9]{2,4}/)[0];

const reorderAttributes = (tabFilters, data, onlyKeys = false) => {
  const idx = (key) => data.map(([k]) => k).indexOf(key);
  const val = (key) => (idx(key) >= 0 ? data[idx(key)][1] : null);
  return onlyKeys ? tabFilters.map((k) => [k, val(k)]) : tabFilters
    .map((k) => [k, val(k)]).filter(([, v]) => v !== null);
};

const formatString = (str) => {
  const reg = regex.find((r) => r.re.test(str));
  return reg ? reg.replace(reg.re, str) : str;
};

const csvToArray = (text, separator) => {
  let p = ''; let row = ['']; const ret = [row]; let i = 0; let r = 0; let s = !0; let
    l;
  for (l of text) {
    if (l === '"') {
      if (s && l === p) row[i] += l;
      s = !s;
    } else if (l === separator && s) l = row[++i] = '';
    else if (l === '\n' && s) {
      if (p === '\r') row[i] = row[i].slice(0, -1);
      row = ret[++r] = [l = '']; i = 0;
    } else row[i] += l;
    p = l;
  }
  return ret;
};

const mashupPropertiesGetHits = (event) => {
  if (event.data) {
    if (event.data.type && event.data.type === '3DXCollabPropertiesResponse') {
      const { hits } = event.data;
      console.log(`Received hits from Cloudview Mashup ${hits}`);

      if (hits && hits.length > 0) {
        return hits;
      }
    }
  }
  return null;
};

const numFormatter = (num) => {
  if (num >= 500 && num < 600000) return `${(num / 1000).toFixed(1)}k`;
  if (num >= 600000 && num < 600000000) return `${(num / 1000000).toFixed(1)}M`;
  if (num >= 600000000 && num < 600000000000) return `${(num / 1000000000).toFixed(1)}B`;
  return num;
};

/**
 * Calculate brightness value by RGB or HEX color.
 * @param color (String) The color value in RGB or HEX (for example: #000000 || #000 || rgb(0,0,0) || rgba(0,0,0,0))
 * @returns (Number) The brightness value (dark) 0 ... 255 (light)
 */
const brightnessByColor = (color) => {
  const isHEX = `${color}`.indexOf('#') === 0;
  const isRGB = `${color}`.indexOf('rgb') === 0;
  let r; let g; let b;
  if (isHEX) {
    const m = `${color}`.substr(1).match(`${color}`.length === 7 ? /(\S{2})/g : /(\S{1})/g);
    if (m) {
      r = parseInt(m[0], 16);
      g = parseInt(m[1], 16);
      b = parseInt(m[2], 16);
    }
  }
  if (isRGB) {
    const m = color.match(/(\d+){3}/g);
    if (m) {
      // eslint-disable-next-line prefer-destructuring
      r = m[0];
      // eslint-disable-next-line prefer-destructuring
      g = m[1];
      // eslint-disable-next-line prefer-destructuring
      b = m[2];
    }
  }
  if (typeof r !== 'undefined') return ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return null;
};

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const getRandomHsl = (minH, maxH, minS, maxS, minL, maxL) => {
  const h = (maxH - minH) * Math.random() + minH;
  const s = (maxS - minS) * Math.random() + minS;
  const l = (maxL - minL) * Math.random() + minL;
  return { h, s, l };
};

const hslToHex = ({ h, s, l }) => {
  const lum = l / 100;
  const a = (s * Math.min(lum, 1 - lum)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = lum - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

const getSinglePhysicalIdfromPekka = (partnumber) => new Promise((resolve, reject) => {
  const url = `${getServiceExtUrl()}pekka-service/rest/query`;
  const headerWAF = {
    'Content-Type': 'application/json',
  };
  const payload = {
    name: 'kpi.get_functional_type',
    titles: [partnumber],
    requestType: 'function',
  };
  const payloadJson = JSON.stringify(payload);

  loadDsApi(pathWAFData).then((wafdata) => wafdata.authenticatedRequest(url, {
    method: 'POST',
    headers: headerWAF,
    data: payloadJson,
    type: 'json',
    crossDomain: true,
    xhrFields: {
      withCredentials: true,
    },
    onComplete: (res) => {
      console.log(res);
      resolve(res?.[0]?.[0]?.physicalid);
    },
    onFailure: (error) => {
      reject(error.message);
    },
  }));
});

function showPopup(content, type = 'error', title = 'Oups!... ') {
  // Create a modal container
  const modal = document.createElement('div');
  modal.style.position = 'fixed';
  modal.style.top = '50%';
  modal.style.left = '50%';
  modal.style.transform = 'translate(-50%, -50%)';
  modal.style.backgroundColor = 'white';
  modal.style.padding = '3px';
  modal.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
  modal.style.zIndex = '1000';
  modal.style.maxWidth = '90%';
  modal.style.maxHeight = '90%';
  modal.style.overflowY = 'auto';
  modal.style.borderRadius = '8px';
  modal.setAttribute('class', `ui label ${type}`)
  // Insert the HTML content
  modal.innerHTML = `
      <div class="ui message ${type}">
        <i id="close-popup" aria-hidden="true" class="close icon"></i>
        <div class="content">
          <div class="header">${title}</div>
          <p>${content}</p>
        </div>
      </div>
  `;

  // Append the modal to the body
  document.body.appendChild(modal);

  // Add close functionality
  document.getElementById('close-popup').addEventListener('click', () => {
    document.body.removeChild(modal);
    document.body.removeChild(backdrop);
  });

  // Add a backdrop (optional)
  const backdrop = document.createElement('div');
  backdrop.style.position = 'fixed';
  backdrop.style.top = '0';
  backdrop.style.left = '0';
  backdrop.style.width = '100%';
  backdrop.style.height = '100%';
  backdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
  backdrop.style.zIndex = '999';
  document.body.appendChild(backdrop);

  backdrop.addEventListener('click', () => {
    document.body.removeChild(modal);
    document.body.removeChild(backdrop);
  });
}

function decodeHtml(html) {
  return new DOMParser().parseFromString(html || "", "text/html").documentElement.textContent;
}

function isHTML(str) {
  // Ensure the input is a non-null, non-undefined string
  if (typeof str !== 'string' || !str.trim() || JSON.isValid(str)) {
      return false;
  }

  try {
      // Parse the string as HTML
      const doc = new DOMParser().parseFromString(str, 'text/html');

      // Check if there are any HTML elements in the parsed content
      return Array.from(doc.body.childNodes).some(node => node.nodeType === 1); // Node.ELEMENT_NODE
  } catch (e) {
      // Return false if parsing fails (should not normally happen with DOMParser)
      return false;
  }
}

const getRandomWidgetColor = () => hslToHex(getRandomHsl(0, 360, 30, 100, 30, 100));

const checkVisualEnhancementLoaded = () => Object.keys(window.parent)
  .filter((a) => Number.isInteger(parseInt(a, 10)))
  .filter((w) => window.parent[w]?.widget?.title === 'Visual Enhancement').length > 0;


JSON.format = function (s) { try { JSON.parse(s); return JSON.stringify(JSON.parse(s), undefined, 2); } catch (e) { return s; } };
JSON.isValid = function (str) { try { JSON.parse(str); return true; } catch (e) { return false; } }

function generateExampleFromSchema(schema) {
  let example = {};
  let elements = schema?.allOf || schema?.anyOf || schema?.oneOf;
  if (elements)
    example = elements.map(el => generateExampleFromSchema(el))
  else {// Loop through each property in the schema
    let props = schema?.properties || schema?.items?.properties ;
    if (props) {
      for (let [key, property] of Object.entries(props)) {

        if (property.example) {
          // If an example is provided, use it
          example[key] = property.example;
        } else {
          // Otherwise, generate a default value based on the type
          switch (property.type) {
            case 'string':
              example[key] = property.example || property.enum ? property.enum?.[0] : 'string';
              break;
            case 'integer':
              example[key] = property.example || 123;
              break;
            case 'boolean':
              example[key] = property.example || true;
              break;
            case 'object':
              example[key] = generateExampleFromSchema(property); // Recursively generate example for nested objects
              break;
            case 'array':
              let gen = generateExampleFromSchema(property.items);
              example[key] = property?.items?.example ? [property.items.example] : (gen && Array.isArray(gen) ? gen : [gen]) || [];
              break;
            default:
              example[key] = isNotNullAndNotEmpty(property.properties) ? generateExampleFromSchema(property) : property.properties;
          }
        }
      } 
    }
  }
  return example;
}

function isNotNullAndNotEmpty(obj) {
  return obj && Object.keys(obj).length > 0;
}


export {
  loadDsApi,
  generateExampleFromSchema,
  getSecurityContext,
  getCadNumber,
  getPDMType,
  getPDMInformation,
  getEngTitle,
  getCloudviewUrl,
  getDashboardUrl,
  getSpaceUrl,
  getUsername,
  getWidgetUrl,
  getServiceExtUrl,
  getLowerLevelAttributes,
  getProgramFromFirstLetter,
  mashupPropertiesGetHits,
  reorderAttributes,
  formatString,
  csvToArray,
  numFormatter,
  brightnessByColor,
  getRandomColor,
  getRandomHsl,
  hslToHex,
  getSinglePhysicalIdfromPekka,
  getRandomWidgetColor,
  checkVisualEnhancementLoaded,
  loadPlatformApi,
  showPopup,
  decodeHtml,
  isHTML
};

export function init(main) {

    let loaded = false;
    let app = {};
    
    window.app = {};
    (new URLSearchParams(window.location.search)).forEach(function(v, k) { app[k] = v });
    app.mode = (app.mode ? app.mode : 'default');
    app.uwaUrl = (app.uwaUrl ? app.uwaUrl : 'default');
    if (app.uwaUrl !== 'default') {
        console.log('not default');
        (new URLSearchParams(app.uwaUrl.split('?')[1])).forEach(function(v, k) { app[k] = v });
        app.env = (app.env ? app.env : 'default');
        let uris = app.uwaUrl.split('/');
        // server dev reverse proxy
        app.baseUrl = (app.baseUrl ? app.baseUrl : 'https://' + uris[2] + '/' + uris[3]);
        // local dev or int8
        app.baseUrl =  'https://' + uris[2] + '/' + uris[3];
    } else {
        console.log('default');
        app.env = (app.env ? app.env : 'default');
        app.baseUrl = (app.baseUrl ? app.baseUrl : 'http://' + window.location.host);
    };

    // Require configurations
    let dsResourceUrl = 'https://r1132100250240-eu1-ifwe.3dexperience.3ds.com/';
    (function () {
		// Configuration Options
		window.parent?.require.config({
            baseUrl: dsResourceUrl + 'resources/20250305T150606Z/en/webapps',
            paths: {
                'ds': dsResourceUrl + 'resources/20250305T150606Z/en/wp/script',
                'DS': dsResourceUrl + 'resources/20250305T150606Z/en/webapps',
                'V6frame': dsResourceUrl + 'resources/20250305T150606Z/en/V6frame/script',
                'wp': dsResourceUrl + 'resources/20250305T150606Z/en/wp/script',
                'ifwe': dsResourceUrl + 'resources/20250305T150606Z/en/ifwe/script'
            },
            config: {
                i18n: {
                    locale: 'en'
                },
                'DS/Logger/Logger': {
                    disableLog: !false
                }
            },
            skipDataMain: true
        });
	})();

    window.app = app;
    console.log(app);

    const initWidget = () => {
        if (document.styleSheets[0]) document.styleSheets[0].disabled = true;
    }

    const addRefreshButtonBehavior = () => {
        // eslint-disable-next-line no-restricted-globals
        const dropdownButton = frameElement?.closest('.moduleWrapper.moduleBorder')?.getElementsByClassName('fonticon-down-open')?.[0];
        if (dropdownButton) {
            dropdownButton.addEventListener('click', (event) => {
                console.log('drop click');
                console.log(event);

                window.parent.document.getElementsByName('refresh')[0].addEventListener('click', () => {
                    console.log('reload widget !');
                    window.location.reload();
                });
            });
        }

    }

    window.onload = function () {
        const waitForWidget = () => {
            if (!loaded) {
                if (app.mode !== 'dev') {
                    if (loaded && window['widget'] !== undefined) {
                        window.widget.addEvent('onLoad', () => {
                            initWidget();
                            main();
                        });
                        window.widget.addEvent('onRefresh', function () {
                            console.log('ootb refresh cancelled');
                        });
                    } else {
                        console.warn('Defered widget Loading');
                        setTimeout(waitForWidget, 100);
                    }
                } else {
                    initWidget()
                    main();
                };
            }
        };
        waitForWidget();
        setTimeout(() => {
            loaded = true;
            main();
        }, 3000);
    };
}

import React from 'react';
import { createRoot } from 'react-dom/client';
import { init } from './lib/utils';
import App from './App';
import DSContext, { DSContextComponent } from './Component/DSContext.jsx'

const AppWithContext = () => {
  return (
      <DSContext.Provider value={DSContextComponent()}>
          <App />
      </DSContext.Provider>
  )
}

const main = () => {
  const domNode = document.getElementById('root');
  const root = createRoot(domNode);
  root.render (<AppWithContext />);
};

init(main);
